import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/seo'
import { Typography, Button } from '@material-ui/core'
import Point from '../components/Point'

class MusicalityLayerWithTriplets extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO title="Swinging Musicality — The Layer With Triplets" />
        <Point>
          So. <br />
          Where do swing rhythms come from?
        </Point>
        <Typography variant={'h6'} gutterBottom>
          Hi!
        </Typography>
        <Typography gutterBottom>
          Music is layers. To get the swing music better it's a good idea to
          understand one of the fundamental layers of music: the 8th note
          triplet layer.
        </Typography>
        <Typography gutterBottom>
          Sounds scary but it is very simple. I've recorded a series of videos
          to explain.
        </Typography>
        <Typography variant={'h4'} gutterBottom>
          1. Download
        </Typography>
        <Typography variant={'h5'} gutterBottom>
          For Android Users
        </Typography>
        Download an app
        <Typography variant={'h5'} gutterBottom>
          For iOS Users
        </Typography>
        Download app
        <br />
        <br />
        <Typography variant={'h4'} gutterBottom>
          2. Caution
        </Typography>
        <Typography gutterBottom>
          Unfortunately (for me) it turned out to be quite conceptual course. :(
          It's about <b>the idea</b> behind the rhythms. It's lacking practical
          things you can follow. But! I have an instagram account and will post
          practical stuff there with time. So the concept + practice would come
          together.
        </Typography>
        <a href={'https://instagram.com/rg.swing.dancer'} target={'_blank'}>
          <Button fullWidth size={'large'} variant={'outlined'}>
            Follow me on Instagram
          </Button>
        </a>
      </Layout>
    )
  }
}

export default MusicalityLayerWithTriplets

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
